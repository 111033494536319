var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container mb-2"},[(_vm.media.storageSpaceMediaFileTypeToken == _vm.MEDIA_TYPE_TOKENS.image)?_c('div',{staticClass:"media-container"},[_c('img',{staticClass:"media-item",attrs:{"src":_vm.fullPathFileFromServer(
          _vm.media.storageSpaceMediaFilePath,
          _vm.media.defaultImg
        ),"onerror":("this.src='" + (_vm.media.defaultImg) + "'")}})]):_vm._e(),(_vm.media.storageSpaceMediaFileTypeToken == _vm.MEDIA_TYPE_TOKENS.video)?_c('div',{staticClass:"media-container"},[_c('video',{staticClass:"media-item",staticStyle:{"cursor":"pointer"},attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.fullPathFileFromServer(
            _vm.media.storageSpaceMediaFilePath,
            _vm.media.defaultImg
          ),"type":"video/mp4","onerror":("this.src='" + (_vm.media.defaultImg) + "'")}}),_vm._v(" Your browser does not support the video tag. ")])]):_vm._e(),(_vm.media.storageSpaceMediaFileTypeToken == _vm.MEDIA_TYPE_TOKENS.audio)?_c('div',{staticClass:"media-container"},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":("" + _vm.BASE_URL + (_vm.media.storageSpaceMediaFilePath))}}),_vm._v(" Your browser does not support the audio element. ")])]):_vm._e(),(_vm.media.storageSpaceMediaFileTypeToken == _vm.MEDIA_TYPE_TOKENS.pdf)?_c('div',{staticClass:"media-container"},[_c('vue-pdf-app',{staticClass:"pdf",attrs:{"pdf":_vm.fullPathFileFromServer(
          _vm.media.storageSpaceMediaFilePath,
          _vm.media.defaultImg
        ),"theme":"dark"}})],1):_vm._e(),(_vm.isExWoPo())?_c('div',{staticClass:"media-container"},[_c('VueDocPreview',{staticClass:"office",attrs:{"url":_vm.fullPathFileFromServer(
          _vm.media.storageSpaceMediaFilePath,
          _vm.media.defaultImg
        ),"type":"office"}})],1):_vm._e(),(_vm.media.storageSpaceMediaFileTypeToken == _vm.MEDIA_TYPE_TOKENS.other)?_c('div',{staticClass:"media-container"},[_c('img',{staticClass:"download",attrs:{"src":require("@/assets/images/download.svg")},on:{"click":function($event){return _vm.viewFileFromServer(_vm.media.storageSpaceMediaFilePath)}}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }